import { openErrBottomToaster, openSuccessBottomToaster, openSuccessToaster } from 'helpers/toaster.helpers'
import { getTranslation } from 'helpers/translations.helpers'
import { stopLoader } from 'redux-thunk/redux/slices/loader.slice'
import { store } from 'App'

export const responseSuccessHandler = (response) => {
  const { responseType = 'json', successMessage, url, loader } = response.config || {}
  if (responseType === 'blob') {
    return { file: response.data, headers: response.headers }
  }
  // // Loader logic to remove loader
  if (loader) {
    store.dispatch(stopLoader(loader))
  }
  // Open Success Toaster
  successMessage && url !== '/roller-coaster-game/cash-out-bet' && openSuccessToaster({ message: getTranslation(successMessage) })
  successMessage && url === '/roller-coaster-game/cash-out-bet' && (response.data.data.winningAmount - response.data.data.betAmount > 0 ? openSuccessBottomToaster({ message: `Bet closed at profit of $${(response.data.data.winningAmount - response.data.data.betAmount).toFixed(2)}` }) : openErrBottomToaster({ message: `Bet closed at loss of $${(response.data.data.winningAmount - response.data.data.betAmount).toFixed(2)}` }))
  return response.data.data
}
