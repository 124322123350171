import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { userSignUp } from 'redux-thunk/thunk/auth.thunk'
import { openErrorToaster } from 'helpers/toaster.helpers'
import ErrorMessage from 'components/layout/ErrorMessage/index'
import Loader from 'components/ui-kit/Loader/index'
import { LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index'
// import SocialLinks from '../SocialLinks/index'
import { useQuery } from 'hooks/useQuery'

const Signup = ({ setShowVerifyEmailPopup, showSignupPopup }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const [showPassword, setShowPassword] = useState({
    showPass: false,
    showConfirmPass: false
  })
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)

  const schema = yup.object().shape({
    userName: yup
      .string()
      .required(`${t('signupErrorsUserNameRequired')}`)
      .min(4, `${t('signupErrorsUserNameMinLength')}`)
      .max(20, `${t('signupErrorsUserNameMaxLength')}`)
      .matches(/^\w+$/, t('signupErrorsUserNamePattern')),
    email: yup
      .string()
      .required(t('signupErrorsEmailRequired'))
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        t('signupErrorsEmailPattern')
      ),
    password: yup
      .string()
      .required(`${t('signupErrorsPasswordRequired')}`)
      .min(8, `${t('signupErrorsPasswordMinLength')}`)
      // .max(16, `${t('signupErrorsPasswordMaxLength')}`)
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      //   t('signupErrorsPasswordPattern')
      // )
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const handleRegisterSubmit = async (userDetails) => {
    const affiliateCode = query.get('affiliateCode')
    const referralCode = query.get('referralCode')
    if (affiliateCode) {
      userDetails.affiliatedBy = affiliateCode
    }
    if (referralCode) {
      userDetails.referrerCode = referralCode
      userDetails.referralLink = window?.location?.href
    }
    try {
      dispatch(userSignUp({ userDetails, setShowVerifyEmailPopup }))
      reset()
    } catch (e) {
      openErrorToaster({ message: e.message })
    }
  }

  useEffect(() => {
    return () => {
      if (location.pathname === ROUTE_PATHS.SIGNUP) {
        history.push(ROUTE_PATHS.HOME)
      }
    }
  }, [])

  return (
    <div
      className={`tab-pane fade ${showSignupPopup && 'show active'}`}
      id='pills-profile'
      role='tabpanel'
      aria-labelledby='pills-profile-tab'
    >
      <div className='register-wrap'>
        <form onSubmit={handleSubmit(handleRegisterSubmit)}>
          <div className='mb-2 form-group'>
            <label htmlFor='user_name' className='form-label text-uppercase'>
              {t('signupUsername')}
            </label> <span className='color-red'>*</span>
            <input
              type='text'
              placeholder={t('signupUsername')}
              className='form-control shadow-none'
              id='user_name'
              name='userName'
              {...register('userName')}
              aria-describedby=''
              maxLength={50}
            />
            {errors && errors.userName && (
              <ErrorMessage
                className='color-red text-danger error-msg login-input ms-2 d-flex'
                message={errors.userName.message}
              />
            )}
          </div>
          <div className='mb-2 form-group'>
            <label htmlFor='email' className='form-label text-uppercase'>
              {t('email')}
            </label> <span className='color-red'>*</span>
            <input
              type='email'
              placeholder={t('emailPlaceholder')}
              className='form-control shadow-none'
              id='email'
              aria-describedby=''
              name='email'
              maxLength={50}
              {...register('email')}
            />
            {errors && errors.email && (
              <ErrorMessage
                className='color-red text-danger error-msg login-input ms-2 d-flex'
                message={errors.email.message}
              />
            )}
          </div>
          <div className='mb-2 form-group'>
            <label
              htmlFor='user_password'
              className='form-label text-uppercase'
            >
              {t('signupPassword')}
            </label> <span className='color-red'>*</span>
            <input
              type={showPassword.showPass ? 'text' : 'password'}
              placeholder={t('********')}
              className='form-control shadow-none'
              id='user_password'
              name='password'
              maxLength={50}
              {...register('password')}
            />
            <a
              onClick={() =>
                setShowPassword((prevState) => ({
                  ...prevState,
                  showPass: !prevState.showPass
                }))}
              className='show-password'
            >
              {!showPassword.showPass
                ? (
                  <i className='fas fa-eye' />
                  )
                : (
                  <i className='fas fa-eye-slash' aria-hidden='true' />
                  )}
            </a>
            {errors && errors.password && (
              <ErrorMessage
                className='color-red text-danger error-msg login-input ms-2 d-flex'
                message={errors.password.message}
              />
            )}
          </div>
          <div className='auth-btn-wrap'>
            <button
              type='submit'
              className='btn btn-secondary text-uppercase w-100'
              disabled={loading}
            >
              {loading
                ? (
                  <Loader variant={LOADER_HANDLER_TYPES.submit} />
                  )
                : (
                  <span>{t('playNow')}</span>
                  )}
            </button>
          </div>
          {/* <div className='auth-socail-wrap'>
            <div className='auth-socail-heading'>
              <h6>
                <span>{t('orContinueWith')}</span>
              </h6>
            </div>
          </div> */}
          {/* <SocialLinks isLoginSignup /> */}
          <div className='auth-mob-instructions d-block d-lg-none'>
            <p>
              {t('usersConsent')}
              <NavLink to='#'>{t('termsOfService')}</NavLink>
            </p>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Signup
