import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, withRouter, NavLink } from 'react-router-dom'
import moment from 'moment'
import { capitalize } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { userLogout } from 'redux-thunk/thunk/auth.thunk'
import {
  setAffilateCode,
  setShowLoginPopup,
  setShowSignupPopup
} from 'redux-thunk/redux/slices/gameSetting.slice'
import { setLoginData } from 'redux-thunk/redux/slices/auth.slice'
import { setUserBonus } from 'redux-thunk/redux/slices/bonus.slice'
import { fetchUserInformation } from 'redux-thunk/thunk/user.thunk'
import {
  getAllCurrency,
  getGameSetting,
  setUpdateAffilateCount
} from 'redux-thunk/thunk/gameSetting.thunk'
import {
  setShowCashierPopup,
  setShowChat,
  setShowNotification,
  setShowSearchPopup,
  setShowSideNav
} from 'redux-thunk/redux/slices/settings.slice'
import Wallet from 'pages/Wallet'
import Login from 'pages/Login'
import WalletSubscription from './components/WalletSubscription/index'
import {
  ProfileUserIcon,
  RefferralsProfileIcon,
  SettingProfileIcon,
  BalanceProfileIcon,
  DepositProfileIcon,
  LogoutProfileIcon,
  ChatIcon,
  DepositIcon,
  WithdrawalIcon,
  BellSolidIcon,
  HeaderMenuIconIcon,
  TelegramIcon,
  ReawardIcon,
  TwitterChatIcon
} from 'components/ui-kit/Icons/svg/index'
import SearchPopup from '../SearchPopup/index'
import { getAuthToken } from 'helpers/cookie.helpers'
import {
  ANNOUNCEMENTS,
  LIVE_DATA,
  ROUTE_PATHS,
  SHOW_SIDE_NAV,
  TOOLTIP_VARIANT
} from 'constants/index'
import { getItem, setItem } from 'helpers/localstorage.helpers'
import { setLiveData } from 'redux-thunk/redux/slices/casino.slice'
import UserDetailsPopup from './components/Chat/components/UserDetailsPopup/index'
import TippingPopup from './components/Chat/components/UserDetailsPopup/components/TippingPopup/index'
import CashierPopup from './components/CashierPopup/index'
import ReportUserPopup from './components/Chat/components/ReportUser/index'
import useLiveAnnouncementSocketNameSpace from 'socket-resources/hooks/useAnnouncement'
import { getAnnouncements } from 'redux-thunk/thunk/announcement.thunk'
import {
  resetAnnouncements,
  setAnnouncements,
  setNewAnnouncement
} from 'redux-thunk/redux/slices/announcement.slice'
import WalletSetting from './components/CurrencyDropdown/components/WalletSettings/index'
import CommonPopup from 'components/ui-kit/CommonPopup/index'
import RightSidebar from './components/RightSidebar/index'
import CustomTooltip from 'components/ui-kit/Tooltip/index'
import ChangeProfilePopup from './components/Chat/components/ChangeProfilePopup/index'
import { getTotalRewards } from 'redux-thunk/thunk/bonus.thunk'
import './header.scss'
import { usePreLogin } from 'hooks/usePreLogin'
import { brandLogoNew } from 'components/ui-kit/Icons/png/index'
import { isMobile } from 'react-device-detect'
import ForgotPasswordPopup from '../ForgotPasswordPopup/index'

const RIGHT_SIDE_BAR_SECTIONS = {
  CHAT_SECTION: 'chatSection',
  NOTIFICATION_SECTION: 'notificationSection'
}

const Header = (props) => {
  const { t } = useTranslation()
  const token = !!getAuthToken()
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = useLocation()
  const { listenLiveAnnouncementUpdateEvent } =
    useLiveAnnouncementSocketNameSpace()
  const { handleRegister } = usePreLogin()
  const isDemoGame = window?.location?.pathname?.match('/demo')
  const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false)
  const [showResetMailPopup, setShowResetMailPopup] = useState(false)
  const [showWalletSetting, setShowWalletSetting] = useState(false)

  const affiliateCode = new URLSearchParams(search).get('affiliateCode')
  const { showLoginPopup, showSignupPopup, demoWallet } = useSelector(
    (state) => state.gameSetting
  )
  // const { showCrashGameHistoryModal,showFairnessModal } = useSelector((state) => state?.crashGame)
  const { showWalletPopup } = useSelector((state) => state.wallet)
  const { announcements, newAnnouncements } = useSelector(
    (state) => state.announcement
  )

  const { user } = useSelector((state) => state.user)
  const { totalRewards } = useSelector((state) => state.bonus)

  const accountsMenuOptions = useMemo(
    () => [
      {
        label: t('accountMenuProfile'),
        id: uuidv4(),
        path: ROUTE_PATHS.PROFILE,
        iconComponent: ProfileUserIcon
      },
      {
        label: t('balance'),
        id: uuidv4(),
        path: ROUTE_PATHS.BALANCE,
        iconComponent: BalanceProfileIcon
      },
      {
        label: t('accountMenuTransactions'),
        id: uuidv4(),
        path: ROUTE_PATHS.TRANSACTIONS,
        iconComponent: DepositProfileIcon
      },
      {
        label: t('deposit'),
        id: uuidv4(),
        path: ROUTE_PATHS.DEPOSIT,
        iconComponent: DepositIcon
      },
      {
        label: t('withdrawals'),
        id: uuidv4(),
        path: ROUTE_PATHS.WITHDRAWALS,
        iconComponent: WithdrawalIcon
      },
      {
        label: t('referrals'),
        id: uuidv4(),
        path: ROUTE_PATHS.REFERRALS,
        iconComponent: RefferralsProfileIcon
      },
      {
        label: t('settings'),
        id: uuidv4(),
        path: ROUTE_PATHS.SETTINGS,
        iconComponent: SettingProfileIcon
      }
    ],
    []
  )

  const {
    showSideNav,
    showChat,
    showSearchPopup,
    showUserDetailsPopup,
    showTipPopup,
    showCashierPopup,
    showReportUserPopup,
    showNotification,
    showProfileChangePopup,
    showForgotPasswordPopup
  } = useSelector((state) => state?.settings)

  const handleLiveAnnouncementData = (e) => {
    dispatch(setAnnouncements(e?.data))
  }

  const handleRightSideBar = (section) => {
    dispatch(setShowChat(section === RIGHT_SIDE_BAR_SECTIONS.CHAT_SECTION))
    dispatch(
      setShowNotification(
        section === RIGHT_SIDE_BAR_SECTIONS.NOTIFICATION_SECTION
      )
    )
  }

  const sidenavExpand = () => {
    setItem(SHOW_SIDE_NAV, !showSideNav)
    dispatch(setShowSideNav(!showSideNav))
    document
      .getElementsByClassName('sidebar-wrap')[0]
      ?.classList.toggle('sidebar-close')
    document
      .getElementsByClassName('page-container')[0]
      ?.classList.toggle('sidebar-close')
    document
      .getElementsByClassName('header')[0]
      ?.classList.toggle('sidebar-close')
  }

  useEffect(() => {
    if (isMobile) {
      dispatch(setShowChat(false))
    }
  }, [isMobile])

  // TODO remove this when got the permanent solution for resizeObserver error
  useEffect(() => {
    dispatch(
      getAnnouncements({
        startDate: moment().subtract(7, 'd').format(),
        endDate: moment().format(),
        limit: 50
      })
    )
    window.addEventListener('error', (e) => {
      if (e.message === t('resizeErr')) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        )
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    })
  }, [])

  useEffect(() => {
    dispatch(resetAnnouncements())
    if (token) {
      dispatch(fetchUserInformation())
      dispatch(setLoginData({ accessToken: getAuthToken() }))
      dispatch(getTotalRewards())
    }
  }, [token])

  useEffect(() => {
    if (affiliateCode) {
      dispatch(setAffilateCode(affiliateCode))
      dispatch(setUpdateAffilateCount({ affiliateCode }))
    }
  }, [window.location.pathname, affiliateCode])

  useEffect(() => {
    if (window.location.pathname === ROUTE_PATHS.SIGNUP) {
      dispatch(setShowSignupPopup(true))
    } else if (window.location.pathname === ROUTE_PATHS.LOGIN) {
      dispatch(setShowLoginPopup(true))
    }
    const cleanUpListener = listenLiveAnnouncementUpdateEvent(
      handleLiveAnnouncementData
    )
    return () => {
      cleanUpListener()
    }
  }, [])

  useEffect(() => {
    dispatch(getAllCurrency())
    dispatch(getGameSetting())
    const liveDataStorage = getItem(LIVE_DATA)
    if (liveDataStorage?.length > 0) {
      for (
        let elementIndex = 0;
        elementIndex <
        (liveDataStorage?.length > 20 ? 20 : liveDataStorage?.length);
        elementIndex++
      ) {
        dispatch(setLiveData(liveDataStorage[elementIndex]))
      }
    }
  }, [])

  useEffect(() => {
    if (announcements?.rows?.length > 0) {
      setItem(ANNOUNCEMENTS, {
        ...announcements,
        rows: announcements?.rows?.slice(0, 10)
      })
      const newAnnouncement = announcements?.rows
        ?.slice(0, 3)
        ?.filter((ele) => ele?.seen || !ele?.status)
      dispatch(
        setNewAnnouncement(
          announcements?.rows?.length === newAnnouncement?.length
            ? false
            : newAnnouncement?.length < 3
        )
      )
    }
  }, [announcements])

  const handleLogOut = () => {
    dispatch(userLogout({ history }))
    // localStorage.clear()
    dispatch(setUserBonus([]))
  }

  const searchPopupAndChatButtonSection = (
    <div className='top-header-right d-none d-xl-flex ms-4'>
      {!showChat && (
        <button
          className='btn btn-primary search-btn ms-2'
          onClick={() =>
            handleRightSideBar(RIGHT_SIDE_BAR_SECTIONS.CHAT_SECTION)}
        >
          <ChatIcon fill='#8A90A2' />
        </button>
      )}
      {token && (
        <button
          className='btn btn-primary search-btn ms-2'
          onClick={() =>
            handleRightSideBar(RIGHT_SIDE_BAR_SECTIONS.NOTIFICATION_SECTION)}
        >
          <BellSolidIcon fill='#8A90A2' />
          {newAnnouncements && <div className='chat-notification-icon' />}
        </button>
      )}
    </div>
  )

  return (
    <>
      <header
        className={`d-flex align-items-center  header ${
          !showSideNav ? 'sidebar-close' : ''
        }`}
      >
        <div className='d-flex align-items-center justify-content-between header-content w-100'>
          <div className='d-flex align-items-center'>
            <NavLink
              to={ROUTE_PATHS.HOME}
              className='brand-logo align-self-center d-none d-xl-block'
            >
              {/* <BrandLogoNewIcon /> */}
              <img src={brandLogoNew} />
            </NavLink>
            <CustomTooltip
              variant={TOOLTIP_VARIANT.SIDEBAR_BUTTON}
              message={showSideNav ? t('collapse') : t('expand')}
              showTooltip
            >
              <NavLink
                to='#'
                className='header-toggle-icon d-none d-xl-block'
                onClick={sidenavExpand}
              >
                <HeaderMenuIconIcon />
              </NavLink>
            </CustomTooltip>
            <NavLink
              to={ROUTE_PATHS.HOME}
              className='brand-logo align-self-center d-block d-xl-none'
            >
              <img src={brandLogoNew} />
            </NavLink>

            <div className='dropdown reward-btn header-user-menu d-md-block'>
              <button
                className='btn btn-primary account-btn dropdown-toggle d-none align-items-center'
                type='button'
              >
                <ReawardIcon />
                <div className='account-btn-mid-content px-2 d-none d-lg-block'>
                  <p className='m-0 text-uppercase'>{t('rewards')}</p>
                </div>
                <div className='dropdown-icon'>
                  <i className='fa fa-caret-down' aria-hidden='true' />
                </div>
              </button>
            </div>
          </div>
          {token
            ? (
              <>
                <div className='header-middile-btn d-flex align-items-center'>
                  <WalletSubscription
                    setShowWalletSetting={setShowWalletSetting}
                  />
                  <button
                    className='btn btn-secondary text-uppercase d-none d-xxl-block'
                    onClick={() => dispatch(setShowCashierPopup(true))}
                  >
                    {t('cashier')}
                  </button>
                </div>
                <div className='header-right d-flex align-items-center'>
                  <div className='dropdown account-btn-wrap header-user-menu'>
                    <button
                      className='btn btn-primary account-btn dropdown-toggle d-flex align-items-center'
                      type='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      {user?.userRank?.imageLogo && (
                        <img
                        // src='/assets/images/homePage/account-status.png'
                          src={user?.userRank?.imageLogo}
                          alt='Badge'
                        />
                      )}
                      <div className='account-btn-mid-content px-2 d-sm-block d-none'>
                        <p className='m-0'>{t('account')}</p>
                        <div className='progress'>
                          <div
                            className='progress-bar'
                            role='progressbar'
                            aria-valuenow={`${totalRewards?.totalWager}`}
                            aria-valuemin='0'
                            aria-valuemax={`${
                            user?.nextRankingLevels?.[0]?.wagerRequirement ||
                            10000
                          }`}
                            style={{
                              width: `${
                              (+totalRewards?.totalWager /
                                +user?.nextRankingLevels?.[0]
                                  ?.wagerRequirement) *
                              100
                            }%`,
                              backgroundColor:
                              user?.nextRankingLevels?.[0]?.moreDetails?.color
                            }}
                          />
                        </div>
                      </div>
                      <div className='dropdown-icon d-sm-flex d-none'>
                        <i className='fa fa-caret-down' aria-hidden='true' />
                      </div>
                    </button>
                    <ul className='dropdown-menu'>
                      <div className='user-name'>
                        <h4 className='m-0'>
                          {t('hello')}, {capitalize(user?.userName) || t('user')}
                        </h4>
                      </div>
                      {accountsMenuOptions.map((item) => (
                        <li key={item.id}>
                          <NavLink to={item.path} className='dropdown-item'>
                            <item.iconComponent /> {item.label}
                          </NavLink>
                        </li>
                      ))}
                      <li>
                        <NavLink
                          to='#'
                          activeClassName='dropdown-item'
                          onClick={handleLogOut}
                        >
                          <LogoutProfileIcon /> {t('accountMenuLogout')}
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  {/* Chat and Games popup */}
                  {searchPopupAndChatButtonSection}
                  {(showChat || showNotification) && <RightSidebar />}
                </div>
                <div className='header-right d-none'>
                  {isDemoGame && (
                    <button className='btn btn-secondary me-2'>
                      {t('demoWallet')}: {Number(demoWallet)?.toFixed(3)}
                    </button>
                  )}
                </div>
              </>
              )
            : (
              <div className='header-right d-flex align-items-center'>
                <div className='header-social-links d-none d-lg-flex align-items-center'>
                  {/* <a
                    href=''
                    onClick={(e) => {
                      e.preventDefault()
                      openInfoToaster({ message: 'Coming Soon!' })
                    }}
                  >
                    <TwitchIconIcon fill='#8A90A2' />
                  </a> */}
                  <a
                    href='https://t.me/wofe_announcements/7'
                    target='_blank' rel='noreferrer'
                  >
                    <TelegramIcon fill='#8A90A2' />
                  </a>
                  <a
                    href='https://x.com/wofeio'
                    target='_blank' rel='noreferrer'
                  >
                    <TwitterChatIcon fill='#8A90A2' />
                  </a>
                </div>

                {isDemoGame && (
                  <button className='btn btn-secondary border-2 me-2'>
                    {t('demoWallet')}: {Number(demoWallet)?.toFixed(3)}
                  </button>
                )}
                <button
                  className='btn auth-btn btn-primary ms-4 me-2'
                  onClick={() => handleRegister({ showLoginPopup: true })}
                >
                  {t('login')}
                </button>
                <button
                  className='btn auth-btn btn-secondary'
                  onClick={handleRegister}
                >
                  {t('signupTitle')}
                </button>
                {/* Chat and Games popup */}
                {searchPopupAndChatButtonSection}
                {(showChat || showNotification) && <RightSidebar />}
              </div>
              )}
        </div>
      </header>

      {showVerifyEmailPopup && (
        <CommonPopup
          modalCloseHandler={() => {
            setShowVerifyEmailPopup(false)
          }}
        >
          <div className='d-flex justify-content-center text-center py-4'>
            <h4>{t('signupRegisterEmailPopupContent')}</h4>
          </div>
        </CommonPopup>
      )}
      {showSignupPopup && (
        <Login
          showSignupPopup={showSignupPopup}
          setShowVerifyEmailPopup={setShowVerifyEmailPopup}
        />
      )}
      {showLoginPopup && (
        <Login setShowVerifyEmailPopup={setShowVerifyEmailPopup} />
      )}
      {showResetMailPopup && (
        <CommonPopup
          modalCloseHandler={() => {
            setShowResetMailPopup(false)
          }}
        >
          <div className='d-flex justify-content-center text-center py-4'>
            <h4>{t('signinResetMailSentSuccessContent')}</h4>
          </div>
        </CommonPopup>
      )}
      {showWalletPopup && <Wallet />}
      {showSearchPopup && (
        <SearchPopup setShowSearchPopup={setShowSearchPopup} />
      )}
      {showUserDetailsPopup && <UserDetailsPopup />}
      {showProfileChangePopup && <ChangeProfilePopup />}
      {showTipPopup && <TippingPopup />}
      {showCashierPopup && <CashierPopup />}
      {showReportUserPopup && <ReportUserPopup />}
      {showWalletSetting && (
        <WalletSetting onClose={() => setShowWalletSetting(false)} />
      )}
      {showForgotPasswordPopup && <ForgotPasswordPopup />}
    </>
  )
}

export default React.memo(withRouter(Header))
