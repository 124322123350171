import React from 'react'
import CryptoFutures from './components/CryptoFutures/index'
import Nft from './components/Nft/index'
import HomePromotions from './components/HomePromotions/index'
import ExploreSection from './components/ExploreSection/index'
import BannerSection from './components/BannerSection/index'
import OriginalsGames from 'pages/Casino/components/Lobby/Components/OriginalsGames/index'
import './home.scss'

function Home () {
  return (
    <div>
      <BannerSection />
      <HomePromotions />
      <OriginalsGames />
      <CryptoFutures />
      <ExploreSection />
      <Nft />
    </div>
  )
}

export default Home
