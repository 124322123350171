import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { setShowForgotPasswordPopup } from 'redux-thunk/redux/slices/settings.slice'
import ErrorMessage from 'components/layout/ErrorMessage/index'
import { forgetPassword } from 'redux-thunk/thunk/auth.thunk'
import { openErrorToaster } from 'helpers/toaster.helpers'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import Loader from 'components/ui-kit/Loader/index'
import './forgotPassword.scss'
import CommonPopup from 'components/ui-kit/CommonPopup/index'

const ForgotPasswordPopup = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t('signupErrorsEmailRequired'))
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        t('signupErrorsEmailPattern')
      )
  })

  const modalCloseHandler = useCallback(() => {
    dispatch(setShowForgotPasswordPopup(false))
  }, [])

  const handleForgotPassword = async (emailData) => {
    try {
      dispatch(forgetPassword({ userNameOrEmail: emailData?.email }))
      reset()
    } catch (e) {
      openErrorToaster({ message: e.message })
    }
  }
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  return (
    <CommonPopup
      modalCloseHandler={modalCloseHandler}
      classes=''
      id='forgot-password-modal'
      modalBodyClasses='p-0'
    >
      <div className='heading-text text-uppercase'>
        {t('forgotPassword')}?
      </div>
      <form onSubmit={handleSubmit(handleForgotPassword)}>
        <div className='mb-2 form-group mt-3'>
          <label htmlFor='userEmail' className='form-label text-uppercase'>{t('signupEmail')}</label> <span className='color-red'>*</span>
          <input
            type='text'
            placeholder={t('emailPlaceholder')}
            className={`form-control form-control-dark shadow-none ${errors?.email ? 'error-input' : ''}`}
            id='email'
            name='email'
            {...register('email')}
            aria-describedby=''
            maxLength={50}
          />
          <div className='error-container'>
            {errors && errors.email && (
              <ErrorMessage
                className='color-red text-danger error-msg login-input ms-2 d-flex'
                message={errors?.email?.message}
              />
            )}
          </div>
        </div>
        <p className='input-note'>Enter your email and we'll send you a new password.</p>
        <div className='d-flex justify-content-end forgot-pass-btn-wrap gap-2'>
          <button
            className='btn auth-btn btn-primary'
            onClick={modalCloseHandler}
          >
            {t('cancel')}
          </button>
          <button
            className='btn btn-secondary'
            disabled={loading}
          >
            {
                loading
                  ? <Loader variant={LOADER_HANDLER_TYPES.submit} />
                  : <span>{t('resetPassword')}</span>
              }
          </button>
        </div>
      </form>
    </CommonPopup>
  )
}

export default ForgotPasswordPopup
