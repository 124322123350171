import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setShowSignupPopup } from 'redux-thunk/redux/slices/gameSetting.slice'
import { ROUTE_PATHS } from 'constants/index'
import { setCryptoCoin } from 'redux-thunk/redux/slices/cryptocoin.slice'
// import SocialLinks from 'pages/Login/components/SocialLinks/index'
// import { ClutchGameOn } from 'components/ui-kit/Icons/png/index'

const BannerSection = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { loginData } = useSelector((state) => state.auth)

  const registerHandler = () => {
    if (loginData?.accessToken) {
      dispatch(setCryptoCoin({ name: 'Roller Coaster', id: '' }))
      history.push(ROUTE_PATHS.ROLLERCOASTER)
    } else {
      dispatch(setShowSignupPopup(true))
    }
  }

  return (
    <section className='home-banner'>
      <div className='home-banner-content'>
        <div>
          <img src='/assets/images/homePage/wofe-banner-logo.png' className='img-fluid d-block mx-auto mb-2 game-on-logo-img' alt='clutch-game-on' />
        </div>
        <div className='home-banner-play-content px-3 d-flex flex-wrap'>
          <a href='javascript:void(0)' className='text-uppercase flex-grow-1'>
            {t('play')}
          </a>
          <a href='javascript:void(0)' className='text-uppercase flex-grow-1'>
            {t('bet')}
          </a>
          <a href='javascript:void(0)' className='text-uppercase flex-grow-1'>
            {t('trade')}
          </a>
          <a href='javascript:void(0)' className='text-uppercase flex-grow-1'>
            {t('win')}
          </a>
        </div>
        <div className='banner-btn-wrap'>
          <button
            type='button'
            className={`btn btn-banner btn-secondary ${location.pathname === ROUTE_PATHS.CASINO ? 'd-none' : ''
              }`}
            onClick={registerHandler}
          >
            {loginData?.accessToken ? t('playNow') : t('registerNow')}
          </button>
        </div>
      </div>
    </section>
  )
}

export default React.memo(BannerSection)
